import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import swr, { SWRConfig } from 'swr';

import { CliSession } from '../../../../../../typings/CliSession.interface';
import {
  Organization,
  OrganizationWithRoleAndPlan,
} from '../../../../../../typings/Organization.interface';
import { OrganizationRole } from '../../../../../../typings/OrganizationMember.interface';
import { Subscription } from '../../../../../../typings/Subscription.interface';
import { Team } from '../../../../../../typings/Team.interface';
import { TeamRole } from '../../../../../../typings/TeamMember.interface';
import { User } from '../../../../../../typings/User.interface';
import APIMethodKeys from '../../../client/APIMethodKeys';
import {
  dashboard_session_initial_values,
  DashboardSession,
} from '../../../typings/DashboardSession.interface';
import { storage_keys } from '../../../utils/storage';
import Icon from '../../common/base/Icon';
import { useToasts } from '../../common/Toast';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UserContext } from '../../contexts/UserContext';
import useEventListener from '../../hooks/useEventListener';
import useLocalStorage from '../../hooks/useLocalStorage';
import useSearchQuery from '../../hooks/useSearchQuery';
import FullscreenLayout from '../../layouts/FullscreenLayout';
import useSWR from 'swr';
import posthog from 'posthog-js';

export type View = 'cli' | 'http';
export const DashboardContext = createContext<{
  view: View;
  updateView: (view: View) => void;
  active_cli_sessions: CliSession[];
  user?: User | null;
  team?: Team | null;
  organizations?: OrganizationWithRoleAndPlan[] | null;
  organization?: Organization | null;
  team_role?: TeamRole;
  organization_role?: OrganizationRole;
  subscription?: Subscription;
  resetContext: () => void;
  mutateTeam: (team: Team) => void;
  mutateSubscription: (subscription: Subscription) => void;
  mutateOrganization: (organization: Organization) => void;
  session: DashboardSession;
  setSession: (session: DashboardSession) => void;
  has_connection: boolean;
  mutateHasConnection: () => void;
  get_started_pending_steps: number;
  has_team_members_or_invites: boolean;
  show_get_started: boolean;
  setShowGetStarted: (show: boolean) => void;
  prev_location: { pathname: string; search: string };
  nav_collapsed: boolean;
  setNavCollapsed: (collapsed: boolean) => void;
  logout: () => void;
}>({
  view: 'http',
  updateView: () => {
    return;
  },
  active_cli_sessions: [],
  mutateTeam: () => {
    return;
  },
  mutateSubscription: () => {
    return;
  },
  mutateOrganization: () => {
    return;
  },
  resetContext: () => {
    return;
  },
  logout: () => {
    return;
  },
  session: {} as DashboardSession,
  setSession: () => {
    return;
  },
  has_connection: false,
  mutateHasConnection: () => {
    return;
  },
  get_started_pending_steps: 2,
  has_team_members_or_invites: false,
  show_get_started: true,
  setShowGetStarted: () => {
    return;
  },
  prev_location: { pathname: '', search: '' },
  nav_collapsed: false,
  setNavCollapsed: () => {
    return;
  },
});

const getAddTeamKeyMiddleWare = (team_id?: string) => (useSWRNext) => {
  return (key, fetcher, config) => {
    return typeof key === 'function'
      ? useSWRNext((...args) => [key(...args), team_id], fetcher, config)
      : useSWRNext(key && [key, team_id], fetcher, config);
  };
};

let prev_location_cache = null;

export const DashboardContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { HookdeckAPI, setAPITeamId } = useContext(GlobalContext);
  const { loading_user, user } = useContext(UserContext);

  const history = useHistory();
  const location = useLocation();
  const toasts = useToasts();
  const search = useSearchQuery<{ team_id: string }>();
  const [prev_location, setPrevLocation] = useState(location);

  const [session, setSession] = useLocalStorage<DashboardSession>(
    `${storage_keys.session.dashboard_session}:${user?.id}`,
    dashboard_session_initial_values,
    true,
  );
  const [last_team_id, setLastTeamId] = useLocalStorage<string | null>(
    `${storage_keys.api_last_team_id}:${user?.id}`,
    null,
  );
  const [show_get_started, setShowGetStarted] = useLocalStorage<boolean>(
    `${storage_keys.show_get_started}:${user?.id}`,
    true,
  );

  const [view, setLastView] = useLocalStorage<View | null>('pref:last_view', null);
  const [nav_collapsed, setNavCollapsed] = useLocalStorage<boolean>('pref:nav_collapsed', false);

  useEffect(() => {
    setPrevLocation(prev_location_cache || location);
    prev_location_cache = location;
  }, [location]);

  const { data: teams, mutate: mutateTeams } = useSWR(APIMethodKeys.teams.listAll(), () =>
    HookdeckAPI.teams.listAll(),
  );

  const { data: organizations, mutate: mutateOrganizations } = useSWR(
    APIMethodKeys.organizations.listAll(),
    () => HookdeckAPI.organizations.listAll(),
  );

  const {
    data: session_context,
    mutate: mutateContext,
    error: context_error,
  } = swr(
    HookdeckAPI.team_id && [APIMethodKeys.session.context(), HookdeckAPI.team_id],
    () => HookdeckAPI.session.context(),
    {
      shouldRetryOnError: false,
      onSuccess: (context) => {
        setLastTeamId(context.team.id);
        setSession({ ...session, team_id: context.team.id });
      },
    },
  );

  const { team, organization, subscription, team_role, organization_role } = session_context || {};

  const { data: webhooks, mutate: mutateHasConnection } = swr(
    HookdeckAPI.team_id && [APIMethodKeys.webhooks.list({ limit: 1 }), HookdeckAPI.team_id],
    () => HookdeckAPI.webhooks.list({ limit: 1 }),
  );

  const { data: members } = useSWR(
    organization && APIMethodKeys.organizations.listMembers(organization.id),
    () => HookdeckAPI.organizations.listMembers(),
  );
  const { data: invites } = useSWR(
    organization && APIMethodKeys.organizations.listMemberInvites(organization!.id),
    () => HookdeckAPI.organizations.listMemberInvites(),
  );

  const has_connection = !!webhooks && webhooks?.count > 0;
  const has_team_members_or_invites =
    (!!members && members?.length > 1) || (!!invites && invites?.length > 0);
  const get_started_pending_steps = useMemo(() => {
    let total_pending_steps = 2;
    if (has_connection) {
      total_pending_steps -= 1;
    }
    if (has_team_members_or_invites) {
      total_pending_steps -= 1;
    }

    return total_pending_steps;
  }, [has_connection, has_team_members_or_invites]);

  const { data: active_cli_sessions } = swr(
    user && team && view === 'cli' && [APIMethodKeys.cli_clients.list({ active: true }), team.id],
    () => HookdeckAPI.cli_clients.getSessions({ active: true }),
    { refreshInterval: 30000 },
  );

  // make sure that the last team id is synced with the current window team id
  const windowRef = useEventListener('focus', () => {
    if (HookdeckAPI.team_id) {
      setLastTeamId(HookdeckAPI.team_id);
    }
  });

  const clearContextStates = () => {
    setAPITeamId(null);
    setLastTeamId(null);
    setSession({ team_id: null });
    mutateTeams(undefined);
    mutateContext(undefined);
    mutateOrganizations(undefined);
  };

  const resetContext = () => {
    clearContextStates();
    return history.replace('/projects');
  };

  useEffect(() => {
    windowRef.current = window;

    const team_id = search.query.team_id ?? session.team_id ?? last_team_id ?? user?.last_team_id;

    if (!team_id && teams && teams.length > 0) {
      setAPITeamId(teams[0].id);
    }

    if (team_id && team_id !== HookdeckAPI.team_id) {
      setAPITeamId(team_id);
    }
  }, [teams]);

  useEffect(() => {
    if (
      user &&
      context_error &&
      context_error?.status === 401 &&
      !location.pathname.includes('/projects') &&
      !location.pathname.includes('/onboarding')
    ) {
      if (session.team_id || last_team_id) {
        toasts.addToast('warning', 'Unauthorized to access the latest project.');
      }
      resetContext();
    }
  }, [user && context_error && context_error?.status === 401]);

  useEffect(() => {
    if (
      !teams ||
      !organizations ||
      !user ||
      (HookdeckAPI.team_id && !session_context && !context_error)
    ) {
      return;
    }

    if (user!.is_guest) {
      if (window.location.pathname.indexOf('/cli/events') === 0) {
        return window.location.replace(process.env.CONSOLE_URL!);
      }
      return window.location.replace('/signup');
    }

    if (
      teams.length === 0 &&
      !organization &&
      !team &&
      !location.pathname.includes('/onboarding')
    ) {
      if (organizations.length > 0) {
        return history.replace('/projects');
      }
      return history.replace('/onboarding');
    }
  }, [user, teams, organizations, session_context, context_error?.response]);

  useEffect(() => {
    if (organization) {
      posthog?.group('organization', organization.id);
    }
    if (team) {
      posthog?.group('team', team.id);
    }

    if ((window as any).$crisp) {
      (window as any).$crisp.push([
        'set',
        'session:data',
        [
          [
            ['organization_id', organization?.id || ''],
            ['organization_name', organization?.name || ''],
            ['feature_flags', JSON.stringify(team?.feature_flags)],
            ['team_id', team?.id || ''],
            ['team_name', team?.name || ''],
            ['max_events_per_second', team?.max_events_per_second || ''],
            ['retention_days', subscription?.retention_days || ''],
            ['plan', subscription?.plan || ''],
          ],
        ],
      ]);
    }
  }, [team, subscription, organization]);

  const logout = () => {
    clearContextStates();
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.replace('/signin');
  };

  const context = useMemo(
    () => ({
      view: view || 'http',
      updateView: (view) => setLastView(view),
      active_cli_sessions: active_cli_sessions || [],
      user,
      team: team || (teams ? (teams.length > 0 ? teams[0] : null) : undefined),
      organization,
      organizations,
      team_role: team_role,
      organization_role: organization_role,
      subscription: subscription,
      mutateTeam: (team: Team) =>
        mutateContext((session_context) => ({ ...session_context, team }) as any),
      mutateSubscription: (subscription: Subscription) =>
        mutateContext((session_context) => ({ ...session_context, subscription }) as any),
      mutateOrganization: (organization: Organization) =>
        mutateContext((session_context) => ({ ...session_context, organization }) as any),
      resetContext: resetContext,
      session,
      setSession,
      has_connection,
      mutateHasConnection,
      has_team_members_or_invites,
      get_started_pending_steps,
      show_get_started,
      setShowGetStarted,
      prev_location,
      nav_collapsed,
      setNavCollapsed,
      logout,
    }),
    [
      mutateContext,
      subscription,
      team,
      organization,
      team_role,
      organization_role,
      user,
      active_cli_sessions,
      view,
      setLastView,
      session,
      setSession,
      teams,
      webhooks,
      has_connection,
      mutateHasConnection,
      get_started_pending_steps,
      has_team_members_or_invites,
      show_get_started,
      setShowGetStarted,
      prev_location,
      nav_collapsed,
      setNavCollapsed,
    ],
  );

  if (
    loading_user ||
    (!context_error &&
      teams === undefined &&
      organization === undefined &&
      team === undefined &&
      subscription === undefined &&
      (webhooks === undefined || session_context === undefined))
  ) {
    return (
      <FullscreenLayout center>
        <Icon icon="loading" muted />
      </FullscreenLayout>
    );
  }

  return (
    <DashboardContext.Provider value={context}>
      <SWRConfig
        key={team?.id}
        value={{
          use: [getAddTeamKeyMiddleWare(HookdeckAPI.team_id)],
        }}>
        {children}
      </SWRConfig>
    </DashboardContext.Provider>
  );
};

export const WithUserOnly: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, user_error } = useContext(UserContext);

  useEffect(() => {
    if (user_error?.status === 401) {
      return window.location.replace(`/signin?redirect=${location.pathname}`);
    }
  }, [user_error]);

  return user ? (
    <>{children}</>
  ) : (
    <FullscreenLayout center>
      <Icon icon="loading" muted />
    </FullscreenLayout>
  );
};

export const WithTeamOnly = ({ children }) => {
  const { team, subscription } = useContext(DashboardContext);

  if (!team || !subscription) {
    return (
      <FullscreenLayout center>
        <Icon icon="loading" muted />
      </FullscreenLayout>
    );
  }

  return children;
};
