import Head from 'next/head';
import Script from 'next/script';

import ThemeProvider from '@hookdeck/theme';

import { ToastProvider } from '../components/common/Toast';
import { GlobalContextProvider } from '../components/contexts/GlobalContext';
import { UserContextProvider } from '../components/contexts/UserContext';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env.POSTHOG_API_KEY || '', {
    api_host: process.env.POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: (posthog) => {
      window.posthog = posthog;
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
  });
}

const MyApp = ({ Component, pageProps }) => {
  const { title, desc, keywords } = Component.configs || {};

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <title>{`${title} - Hookdeck`}</title>
        {desc && <meta name="description" content={desc} />}
        {keywords && keywords.length > 0 && <meta name="keywords" content={keywords.join(',')} />}
        <meta property="og:title" content={`${title} - Hookdeck`} />
        {desc && <meta name="description" content={desc} />}
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          window.$crisp=[];window.CRISP_WEBSITE_ID="${process.env.CRISP_WEBSITE_ID}";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
          $crisp.push(["safe", true])
          $crisp.push(["do", "chat:hide"])
          $crisp.push(["on", "chat:closed", function() { $crisp.push(["do", "chat:hide"]) }])
          $crisp.push(["on", "message:received", function() { $crisp.push(["do", "chat:show"]) }])
          window.CRISP_READY_TRIGGER = function() {
            var unread = $crisp.get("chat:unread:count")
            if (unread > 0) {
              $crisp.push(["do", "chat:show"])
            } else {
              $crisp.push(["do", "chat:hide"])
            }
          };
        `,
        }}
      />
      <ThemeProvider>
        <ToastProvider>
          <GlobalContextProvider
            configs={{
              APP_URL: process.env.APP_URL!,
              API_URL: process.env.UI_API_URL ?? process.env.API_URL!,
              SENTRY_DSN: process.env.SENTRY_DSN!,
              ROOT_DOMAIN: process.env.ROOT_DOMAIN!,
              WEBSITE_URL: process.env.WEBSITE_URL!,
              ENV: process.env.ENV!,
            }}>
            <UserContextProvider>
              <PostHogProvider client={posthog}>
                <Component {...pageProps} />
              </PostHogProvider>
            </UserContextProvider>
          </GlobalContextProvider>
        </ToastProvider>
      </ThemeProvider>
    </>
  );
};

export default MyApp;
